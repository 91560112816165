import styles from '../../styles/auth/auth.module.css';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import { User } from '../../models/user';
import { UserAuthRequest } from '../../api/user/dto/UserAuthRequest';
import { Input } from '../../components/UI/input/input';
import { Button } from '../../components/UI/Button/Button';

export const LoginPage: React.FC = observer(() => {

	const navigate = useNavigate();
	// const location = useLocation();

	const {register, handleSubmit, formState: {errors}} = useForm();
	const api = useApi();
	const store = useStores();

	const onSubmit = async (formData: FieldValues) => {
		try {
			store.notificationsStore.showPreloader();
			const {body} = await api.user.login(formData as UserAuthRequest);

			api.saveTokens(body?.access_token, body?.refresh_token);

			const { body: user } = await api.user.me();
			store.userStore.setUser(user as User);
			store.notificationsStore.hidePreloader();
			if (user?.role === 'admin') {
				navigate('/meditations');
			} else {
				navigate('/profile');
			}
		} catch (error) {
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setError(error as string)
		}
	};

	return (
		<div className={styles.screen}>
			<div className={styles.auth}>
				<h1>Войти</h1>
				<div className={styles.form}>
					<form onSubmit={handleSubmit(onSubmit)}>

						<Input 
							textLabel='Введите Email' type='email' id='email' placeholder='Введите Email'
							{...register('email', {required: {value: true, message: 'Email обзятелен'}})}
							error={errors?.email}
						/>

						<Input 
							textLabel='Введите пароль' type='password' id='password' placeholder='Введите пароль'
							{...register('password', {required: {value: true, message: 'Пароль обзятелен'}})}
							error={errors?.password}
						/>
						<Button appearance='auth' id='login'>Войти</Button>
					</form>
					{/* <Link className={cn(styles.forgotPassword, styles.hover)} to={'/forget-password'}>Забыли пароль?</Link> */}
				</div>
				{/* <p className={styles.forgotPassword}>
				У вас нет аккаунта? <Link className={styles.highlight} to={'/registration'}>Зарегистрируйтесь прямо сейчас</Link>
				</p> */}
			</div>
		</div>
	);
});