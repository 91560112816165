/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './Videos.module.css';
import { ReactComponent as Close } from '../ico/close.svg';
import { ReactComponent as DeleteIco } from '../ico/delete.svg';
import { Button } from '../../UI/Button/Button';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import { useApi } from '../../../hooks/useApi';
import { Input } from '../../UI/input/input';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { AppSettings } from '../../../appSettings';
import { VideosModel } from '../../../models/videos';

interface PopupProps {
  item: VideosModel | null;
  onClose: () => void;
	update: () => void;
}

const VideosPopUP: React.FC<PopupProps> = observer(({ item, onClose, update }) => {

	const store = useStores();
	const api = useApi();

	const [image, setImage] = useState<string | null>(null);
	const [title, setTitle] = useState<string>('');
	const [url, setUrl] = useState<string>('');

	const [imageFile, setImageFile] = useState<File | null>(null);

	const [disable, setDisable] = useState<boolean>(true);

	useEffect(() => {
		if (item) {
			setTitle(item.title ?? '');
			setUrl(item.url ?? '');
			setImage(AppSettings.api.url + item.image);
		}
	}, [item]);

	useEffect(() => {
		if (title !== '' && url !== '') {
			setDisable(false);
		} else {
			setDisable(true);
		}
	},[title, url]);


	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	const handleButtonClick = async () => {
		try {
			store.notificationsStore.showPreloader();

			const formData = new FormData();

			formData.append('title', title);
			formData.append('url', url);

			if (imageFile) {
				formData.append('image', imageFile);
			}

			if (item) {
				await api.videos.update(formData, item.id as string);
			} else {
				await api.videos.create(formData);
			}

			update();
			onClose();

			store.notificationsStore.setNotifications('Успешно выполнено');

		} catch (e) {
			store.notificationsStore.setError('Ошибка при выполнении')
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const deleteItem = async () => {
		try {
			store.notificationsStore.showPreloader();
			await api.videos.delete(item?.id as string);
			store.notificationsStore.setNotifications('Успешно удалено');
			update();
			onClose();
		} catch (e) {
			store.notificationsStore.setError('Ошибка при удалении')
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const prewPhoto = (file: File) => {
		setImage(URL.createObjectURL(file));
	}
	
	return (
		<>
			<div className={styles.container} onClick={handleOverlayClick}>
				<div className={styles.form}>
					<Close className={styles.close} onClick={onClose}/>
					<h3>{!item ? 'Добавление' : 'Изменение'} видео</h3>
					<div className={styles.line} style={{marginBottom: 20}}/>

					<div style={{maxWidth: '90%', height: 'auto', minHeight: 150}} className={cn(styles.imageArea, {[styles.imageSet]: image})}>
						<input type='file' name='file' id='file' className={styles.inputfile} accept='image/jpeg,image/png'
							onChange={e => {
								if (e.target.files && e.target.files[0]) {
									prewPhoto(e.target.files[0]);
									setImageFile(e.target.files[0]);
								}
							}}
						/>
						{!image && <label htmlFor='file' className={styles.labelImage}><p>Нажмите, чтобы выбрать фото</p></label>}
						{image &&
							<>
								<div className={styles.deleteImage} onClick={() => {setImage(null);}}><DeleteIco/></div>
								<img src={image} alt='Фото' width='100%' height='100%'/>
							</>
						}
					</div>

					<Input style={{maxWidth: '90%'}} value={title} placeholder='Заголовок' textLabel='Заголовок' onChange={(event) => setTitle(event.target.value)}/>

					<Input style={{maxWidth: '90%'}} value={url} placeholder='Ссылка' textLabel='Ссылка' onChange={(event) => setUrl(event.target.value)}/>

					<div className={styles.line} style={{marginBottom: 10}}/>

					<div style={{display: 'flex', width: '90%', justifyContent: 'center'}}>
						<Button disabled={disable} onClick={handleButtonClick} style={{margin: 5, maxWidth: 320, marginBottom: 25}} appearance='auth'>{!item ? 'Создать' : 'Изменить'}</Button>
						{ item && <Button onClick={deleteItem} style={{margin: 5, maxWidth: 320, marginBottom: 25}} appearance='link'>Удалить</Button> }
					</div>

				</div>
			</div>
		</>
	);
});

export default VideosPopUP;
