import { BannersModel } from '../../models/banners';
import { ContactModel } from '../../models/contact';
import ApiClient from '../ApiClient';
import { NiceResponse } from '../dto/NiceResponse';

export class ContactApi {
	constructor(private api: ApiClient) { }

	path = '/contacts';

	async get(skip: number, take: number) {
		const resp = await this.api.client.get<NiceResponse<ContactModel[]>>(this.path + '/list?skip=' + skip + '&take=' + take);
		return resp.data;
	}
}